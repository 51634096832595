import React from 'react';
import classNames from 'classnames';
import styles from './RichText.module.scss';

const RichText = ({ html = '', colors = null }) => {
    const classes = classNames(styles['RichText'], {
        [styles[`RichText--${colors}`]]: colors,
    });

    return (
        <div dangerouslySetInnerHTML={{ __html: html }} className={classes} />
    );
};

export default RichText;
